'use strict';

const scrollToElement = require('../components/scrollToElement');
const breakpoints = require('../breakpoints');
const initPopOver = require('../components/initPopOver');
const productTilePlaceholder = require('../components/productTilePlaceholder');
const datalayer = require('../components/datalayer');
const throttle = require('lodash/throttle');
const initYotpoReview = require('../components/initYotpoReview');
const noUiSlider = require('nouislider');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

function updateWishlistHeart() {
    var wishlist = window.wishlist;
    var wishlistItems = wishlist ? wishlist.items : {};
    document.querySelectorAll('.product').forEach(function (product) {
        var pid = product.dataset.pid;
        if (wishlistItems.length > 0) {
            wishlistItems.forEach(function (item) {
                if (item.pid === pid) {
                    product.querySelector('.wishlist-tile').classList.add('added');
                }
            });
        }
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.hero-filter');
    updateDom($results, '.refinements');
    updateWishlistHeart();
}

/**
 * Update the canonical URL based on the provided updated URL.
 *
 * @param {string} updatedURL - The updated URL to use for the canonical URL.
 * @return {void}
 */
function updateCanonicalUrl(updatedURL) {
    if (!updatedURL) {
        return;
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    const url = new URL(updatedURL);
    const canonicalOrigin = url.origin;
    const canonicalPath = url.pathname;
    let canonicalURL = `${canonicalOrigin}${canonicalPath}`;

    const page = url.searchParams.get('page');

    if (page) {
        canonicalURL = `${canonicalURL}?page=${page}`;
    }

    if (canonical) {
        canonical.setAttribute('href', canonicalURL);
    }
}

function getUrlParameters() {
    const params = new URLSearchParams(window.location.search);
    const queryParams = {};

    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    return queryParams;
}

function shouldAddNoindexNofollow() {
    const params = getUrlParameters();
    const noindexNofollowParams = ['pmax', 'pmin', 'prefn1', 'srule'];

    if (window.location.pathname.includes('/search')) {
        return true;
    }

    return Object.keys(params).some(param => noindexNofollowParams.includes(param));
}

function updateNoindexNofollow() {
    const shouldAddMetaTag = shouldAddNoindexNofollow();
    const METATAG_CONTENT = 'noindex, nofollow';

    let metaTag = document.querySelector(`meta[content="${METATAG_CONTENT}"]`);

    if (shouldAddMetaTag) {
        if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.name = 'robots';
            metaTag.content = `${METATAG_CONTENT}`;
            document.head.appendChild(metaTag);
        } else {
            metaTag.content = `${METATAG_CONTENT}`;
        }
    } else if (metaTag) {
        document.head.removeChild(metaTag);
    }
}

/**
 * Updates the AJAX URL and updates the browser history.
 *
 * @param {JQuery} $results - The jQuery object containing the results.
 * @return {void} This function does not return anything.
 */
function updateAjaxURL($results) {
    const updatedURL = $results.find('.permalink')[0].value;
    updateCanonicalUrl(updatedURL);
    window.history.pushState('', '', updatedURL);
    updateNoindexNofollow();
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.js-sort-menu',
        '.results-count-js'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    updateAjaxURL($results);
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            initPopOver();
            initYotpoReview.refreshWidgets();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
    updateWishlistHeart();
}

function openShowMoreAfterContentLoad() {
    const refinementIndexSelected = {};
    document.querySelectorAll(".js-refinement [data-showmore='true']").forEach((item) => {
        const refinementCheckbox = item.querySelector("[type='checkbox']");
        const refinementSection = refinementCheckbox?.closest('.js-refinement');
        const refinementSectionIndex = refinementSection?.dataset.refinementIndex;
        if (refinementCheckbox?.checked && !refinementIndexSelected[refinementSectionIndex]) {
            refinementIndexSelected[refinementSectionIndex] = 1;
            refinementSection?.querySelectorAll("[data-showmore='true']").forEach((showItems) => {
                showItems.classList.remove('d-none');
            });
            const refinementShowMoreLink = refinementSection?.querySelector('.js-refinement-showmore-link');
            refinementShowMoreLink.dataset.showmoreStatus = 'false';
            refinementShowMoreLink.innerText = `${refinementShowMoreLink?.dataset?.showlessText}`;
        }
    });
}

/**
 * Toggles the collapse state of the refinement category based on screen width and stored state.
 * Automatically collapses the category on mobile devices if no explicit state is stored.getSitePathFromUrl
 */
function toggleRefinementCollapse() {
    const categoryTabId = 'refinement-category';
    const categoryTab = document.getElementById(categoryTabId);
    const storedStates = JSON.parse(localStorage.getItem('refinementsState')) || {};

    if (breakpoints.down('lg') && storedStates[categoryTabId] === undefined) {
        $(categoryTab).collapse('hide');
    }
}

function initRangeSlider(rangeSlider, $inputFrom, $inputTo) {
    if (!rangeSlider
        || !$inputFrom.length
        || !$inputTo.length
        || !noUiSlider
        || !rangeSlider.hasAttribute('data-min')
        || !rangeSlider.hasAttribute('data-max')
    ) {
        return;
    }

    const currentMin = parseInt($inputFrom.val());
    const currentMax = parseInt($inputTo.val());
    const min = parseInt(rangeSlider?.getAttribute('data-min'));
    const max = parseInt(rangeSlider?.getAttribute('data-max'));

    noUiSlider.create(rangeSlider, {
        start: [currentMin, currentMax],
        connect: true,
        range: {
            min,
            max
        }
    });

    rangeSlider.noUiSlider.on('slide', (values) => {
        const newMin = parseInt(values[0]);
        const newMax = parseInt(values[1]);

        $inputFrom.val(newMin);
        $inputTo.val(newMax);
    });

    rangeSlider.noUiSlider.on('end', (() => {
        $inputTo.trigger('change');
    }));
}

function initPriceRangeSlider() {
    const priceSlider = document.getElementById('priceSlider');
    const $inputFrom = $('#price-refinement-pmin');
    const $inputTo = $('#price-refinement-pmax');

    initRangeSlider(priceSlider, $inputFrom, $inputTo);
}

function initAttributeRangeSliders() {
    $('.js-attribute-range-slider-container').each(function () {
        const $container = $(this);
        const $inputFrom = $container.find('.js-range-slider-input-from:first');
        const $inputTo = $container.find('.js-range-slider-input-to:first');
        const slider = $container[0].querySelector('.js-attribute-range-slider');

        initRangeSlider(slider, $inputFrom, $inputTo);
    });
}

function applyfilterCall(e, url) {
    const event = new CustomEvent('search:filtered');

    e.preventDefault();
    e.stopPropagation();

    const isFilterResetButton = e.target.classList.contains('reset');

    const productGrid = document.querySelector('.js-product-grid');
    const listingPageStartElelement = document.querySelector('.js-listing-content-start');
    if (productGrid && listingPageStartElelement) {
        productGrid.innerHTML = productTilePlaceholder(20);
        scrollToElement(listingPageStartElelement);
    }

    $(this).trigger('search:filter', e);
    $.ajax({
        url: url,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: url
        },
        method: 'GET',
        success: function (response) {
            parseResults(response);
            openShowMoreAfterContentLoad();
            initPopOver();
            initYotpoReview.refreshWidgets();
            initPriceRangeSlider();
            initAttributeRangeSliders();

            if (isFilterResetButton && breakpoints.down('md')) {
                $('.refinement-bar, .modal-background').hide();
            }
            document.dispatchEvent(event);
        },
        error: function () {}
    });
}

function handleRangeChange($inputFrom, $inputTo, $slider) {
    // eslint-disable-next-line no-unused-vars
    let min = parseInt($slider.attr('data-min'));

    let max = parseInt($slider.attr('data-max'));
    let newMin = parseInt($inputFrom.val());
    let newMax = parseInt($inputTo.val());

    if (newMin === newMax && newMin === max) {
        $inputFrom.val(newMin - 1);
        $inputTo.val(newMin);
    } else if (newMin === newMax) {
        $inputFrom.val(newMin);
        $inputTo.val(newMin + 1);
    }
}

module.exports = {
    init: function () {
        initPriceRangeSlider();
        initAttributeRangeSliders();
        updateNoindexNofollow();
    },
    filter: function () {
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', function () {
            toggleRefinementCollapse();
            $('.refinement-bar, .modal-background').show();
            $('.refinement-bar').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
            $('.refinement-bar .close').focus();
        });
    },

    closeRefinements: function () {
        // Refinements close button
        $('.container').on('click', '.refinement-bar button.close, .modal-background', function () {
            $('.refinement-bar, .modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            $('.btn.filter-results').focus();
        });
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        window.onresize = throttle(() => {
            $('.refinement-bar').hide();
            $('.modal-background').hide();

            if (breakpoints.up('lg')) {
                $('.refinement-bar').show();
                $('.modal-background').hide();
            }
        }, 100);
    },

    sort: function () {
        // Handle sort order menu selection
        $('.container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();
            $('.product-grid').empty().html(productTilePlaceholder(20));
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    updateWishlistHeart();
                    initPopOver();
                    initYotpoReview.refreshWidgets();
                    $.spinner().stop();
                    updateAjaxURL($(response));
                },
                error: function () {}
            });
        });
    },

    pagination: function () {
        document.querySelectorAll('.container').forEach((container) => {
            container.addEventListener('click', function (e) {
                if (e.target.matches('.pagination .page-item a')) {
                    e.stopPropagation();
                    e.preventDefault();

                    var showMoreUrl = e.target.getAttribute('data-href');

                    const productGridSidebar = document.querySelector('.js-product-grid');
                    if (productGridSidebar) {
                        productGridSidebar.innerHTML = productTilePlaceholder(20);
                    }

                    const listingPageStartElelement = document.querySelector('.js-listing-content-start');
                    if (listingPageStartElelement) {
                        scrollToElement(listingPageStartElelement);
                    }

                    // Trigger a custom event 'search:showMore' on the clicked element
                    var showMoreEvent = new Event('search:showMore');
                    e.target.dispatchEvent(showMoreEvent);

                    // Use the Fetch API to send an AJAX request
                    fetch(showMoreUrl)
                        .then((response) => {
                            if (response.ok) {
                                return response.text();
                            }

                            return false;
                        })
                        .then((data) => {
                            updateAjaxURL($(data));
                            if (productGridSidebar) {
                                productGridSidebar.innerHTML = data;
                            }
                            updateSortOptions(data);
                            initPopOver();
                            initYotpoReview.refreshWidgets();

                            scrollToElement(listingPageStartElelement);

                            const productTtiles = productGridSidebar.querySelectorAll('[data-tracking-product-tile]');
                            datalayer.productTileTracking(productTtiles);

                            const jsonLdScript = document.querySelector('head script[type="application/ld+json"]');
                            const schemaData = document.querySelector('.schemaData');
                            if (jsonLdScript && schemaData) {
                                jsonLdScript.innerHTML = schemaData.innerHTML;
                                schemaData.remove();
                            }
                        })
                        .catch(() => {});
                }
            });
        });
    },

    applyPriceFilter: function () {
        $('.container').on(
            'change',
            '#price-refinement-pmin , #price-refinement-pmax',
            function (e) {
                const $inputFrom = $('#price-refinement-pmin');
                const $inputTo = $('#price-refinement-pmax');
                const $slider = $('#priceSlider');

                if (!$inputFrom || !$inputTo || !$slider) {
                    return;
                }

                handleRangeChange($inputFrom, $inputTo, $slider);

                let newMin = parseInt($inputFrom.val());
                let newMax = parseInt($inputTo.val());
                const url = new URL($('#price-refinement').data('href'), document.baseURI);

                url.searchParams.set('pmin', newMin.toString().replace('.', ','));
                url.searchParams.set('pmax', newMax.toString().replace('.', ','));

                applyfilterCall(e, url.toString());
            }
        );
    },

    applyFilter: function () {
        // Handle refinement value selection
        $('.container').on(
            'click',
            '.refinements li button, .swatch-filter button',
            function (e) {
                applyfilterCall(e, $(this).data('href'));
            }
        );
    },

    applyHeroFilter: function () {
        $('.container').on(
            'click',
            '.hero-filter li button, .swatch-filter button',
            function (e) {
                applyfilterCall(e, $(this).data('href'));
            }
        );
    },

    initRangeFiltersFieldsChange: function () {
        $('.container').on(
            'change',
            '.js-range-slider-input',
            function (e) {
                const $filterRootElem = $(this).closest('[data-href]');
                const filterRootElemUrl = $filterRootElem.data('href');
                const refinementAttributeId = $filterRootElem.data('refinement-attribute-id');
                if (!filterRootElemUrl || !refinementAttributeId) {
                    return;
                }
                const $inputFrom = $filterRootElem.find('.js-range-slider-input-from:first');
                const $inputTo = $filterRootElem.find('.js-range-slider-input-to:first');
                const $slider = $filterRootElem.find('.js-attribute-range-slider:first');

                if (!$inputFrom || !$inputTo || !$slider) {
                    return;
                }

                handleRangeChange($inputFrom, $inputTo, $slider);

                let newMin = parseInt($inputFrom.val());
                let newMax = parseInt($inputTo.val());
                const url = new URL(filterRootElemUrl, document.baseURI);

                let urlKey = '';
                let params = new URLSearchParams(filterRootElemUrl.split('?')[1]);
                params.forEach((value, key)=> {
                    if (value === refinementAttributeId) {
                        urlKey = key;
                    }
                });
                let urlKeyIndex = urlKey.toString().replace('prefn', '');

                url.searchParams.set(`prefmin${urlKeyIndex}`, newMin.toString().replace('.', ','));
                url.searchParams.set(`prefmax${urlKeyIndex}`, newMax.toString().replace('.', ','));

                applyfilterCall(e, url.toString());
            }
        );
    },

    resetFilter: function () {
        // Handle refinement reset click
        $('.container').on(
            'click',
            '.filter-value button, .refinement-bar button.reset',
            function (e) {
                let url = $(this).data('href');
                applyfilterCall(e, url);
            }
        );
    },

    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    viewToggler: function () {
        const VIEW_TYPE_LIST = 'list';
        const VIEW_TYPE_GRID = 'grid';
        const ACTIVE_CLASS_NAME = 'active';

        const viewToggler = document.querySelector('.js-view-toggler');

        if (!viewToggler) {
            return;
        }

        const viewButtons = viewToggler?.querySelectorAll('.js-view-toggler-btn');
        const { classtotoggle, target, userpeference } = viewToggler.dataset;
        const targetElement = document.querySelector(target);

        if (!targetElement || !viewButtons || !targetElement || !target || !userpeference) {
            return;
        }

        if (localStorage.getItem(userpeference) === VIEW_TYPE_LIST || (localStorage.getItem(userpeference) === null && targetElement.dataset.viewType === VIEW_TYPE_LIST)) {
            targetElement.classList.add(classtotoggle);
            viewButtons.forEach(viewType => {
                if (viewType.dataset?.view === VIEW_TYPE_LIST) {
                    viewType.classList.add(ACTIVE_CLASS_NAME);
                } else {
                    viewType.classList.remove(ACTIVE_CLASS_NAME);
                }
            });
        }

        viewButtons.forEach((view) => {
            view.addEventListener('click', (event) => {
                event.preventDefault();
                const selectedViewButton = event.target.closest('.js-view-toggler-btn');

                if (!selectedViewButton) {
                    return;
                }

                viewButtons.forEach(viewType => viewType.classList.remove(ACTIVE_CLASS_NAME));
                selectedViewButton.classList.add(ACTIVE_CLASS_NAME);
                const selectedView = selectedViewButton.dataset.view;

                if (selectedView === VIEW_TYPE_LIST) {
                    targetElement.classList.add(classtotoggle);
                    localStorage.setItem(userpeference, VIEW_TYPE_LIST);
                } else {
                    targetElement.classList.remove(classtotoggle);
                    localStorage.setItem(userpeference, VIEW_TYPE_GRID);
                }
            });
        });
    },
    seoReadMoreLink: function () {
        document.querySelector('.js-seo-top-readmore-link')?.addEventListener('click', (event) => {
            event.preventDefault();
            const seoTopReadmoreLink = event.target;
            if (seoTopReadmoreLink) {
                const scrollToTargetElementSlector = seoTopReadmoreLink.dataset?.target;
                if (scrollToTargetElementSlector) {
                    const scrollToTargetElement = document.querySelector(scrollToTargetElementSlector);
                    scrollToElement(scrollToTargetElement);
                }
            }
        });
    },
    showMoreLink: function () {
        document.addEventListener('click', function (evt) {
            const target = evt.target.closest('.js-refinement-showmore-link');
            if (target) {
                evt.preventDefault();
                evt.stopPropagation();
                const refinementSection = target?.closest('.js-refinement');
                if (target?.dataset?.showmoreStatus === 'true') {
                    refinementSection.querySelectorAll("[data-showmore='true']").forEach((item) => {
                        item.classList.remove('d-none');
                    });
                    target.dataset.showmoreStatus = 'false';
                    target.innerText = `${evt.target?.dataset?.showlessText}`;
                } else {
                    refinementSection.querySelectorAll("[data-showmore='true']").forEach((item) => {
                        item.classList.add('d-none');
                    });
                    target.dataset.showmoreStatus = 'true';
                    target.innerText = `${evt.target?.dataset?.showmoreText}`;
                    scrollToElement(refinementSection);
                }
            }
        });
    },
    filterModalClose: function () {
        document.querySelector('.js-filter-apply')?.addEventListener('click', ()=>{
            $('.refinement-bar, .modal-background').hide();
        });
    },
    initHistoryBackForwardRefreshResults: function () {
        window.addEventListener('popstate', () => {
            window.location.reload();
        });
    }
};
